

.maintenace{
    background-image: url('/public/maintenance.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 500px;
}
.header, .footer{
    width:100%;
    flex-grow: 1;   
    /* display: flex; */
    justify-content: space-between;
    /* background-color: #151515; */
    background-color: rgba(35, 33, 39, 0.881);
    text-align: start;
    /* position: fixed; */
    /* padding-bottom: 30px; */
    /* margin-block-end: 0px; */
    /* border-spacing: 100px; */
}

#nav:hover, #collasible-nav-dropdown:hover{
    background-color:grey;
    color: black;
}
.form-switch .form-check-input {width: 10em; height: 20px; margin-right: 5px;}
#navBox, #nav, #collasible-nav-dropdown{
    border-radius: 10px;
}
/* .footer, .footerDiv{
    height: 6vh;
    background-color: rgba(35, 33, 39, 0.881);

} */
.app{
    height: 100%;
    /* height:inherit; */
    width: 100%;
    /* display: flex; */
    /* overflow: scroll; */
    /* background-color: rgba(153, 51, 61, 0.247); */
    /* flex-grow: 1; */
}
.home{
    
    height: 100%;
    margin-top: 75px;
    /* background-color: rgba(61, 36, 39, 0); */
    /* overflow:auto; */
    /* background-color: #333; */
    /* background-color: lightpink; */
}
html, body{
    height: 100%;
    margin: 0;
    /* background-color: indigo; */
}
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}
.imageIconContainer {
    
}
.imageIcon {
    
        border: 2px;
        border-radius: 100%;
        border-block-style: double;
        width: 20%;
        height: 30%;;
        margin-left: 40%;
        font-size: 49;
        font-weight: 600;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        /* text-decoration */
     
}