.doc-content{
        background-color:rgb(255, 255, 255);
        /* border-radius: 5%; */
        /* border-style: ridge; */
        margin-top: 1rem;
        margin-bottom: 1rem;
        /* border-top-width: 1px; */
        color:rgb(11, 15, 8);
        font-weight: 400;
        font-family:'Times New Roman', Times, serif;
}
.doc-endpoint-body{
    overflow-x: 'hidden';
    background-color: inherit
}
#side_bar{ 
    position: fixed;
    background-color: rgb(250, 255, 255);
    width: 250px;
    height: 100%;
    overflow: scroll;
    margin-right: 8px;

 }
.doc-side-bar-list{
    font-size:medium;
    font-weight: 600;
    display: flex;
    align-items: center;
    
}

#contents {
    background-color: rgb(245, 250, 255);
}
